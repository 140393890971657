import { marked } from "marked";
import { useEffect, useState } from "react";
import { language } from "../i18n/index.js";

const year = new Date().getFullYear();
const Texts = {
  en: {
    WelcomeTop: require("./Texts/en/WelcomeTop.md"),
    WelcomeBottom: require("./Texts/en/WelcomeBottom.md"),
    PrivacyPolicy: require("./Texts/en/PrivacyPolicy.md"),
  },
  de: {
    WelcomeTop: require("./Texts/de/WelcomeTop.md"),
    WelcomeBottom: require("./Texts/de/WelcomeBottom.md"),
    PrivacyPolicy: require("./Texts/de/PrivacyPolicy.md"),
  },
};

export default function Welcome({ config, showNext, language: _language }) {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [textTop, setTextTop] = useState("");
  const [textBottom, setTextBottom] = useState("");
  const [textPrivacy, setTextPrivacy] = useState("");

  useEffect(() => {
    async function fetchTextTop() {
      let Text = await fetch(Texts?.[language()]?.WelcomeTop)
        .then(response => response.text())
        .then(text => marked(text));

      setTextTop(Text);
    }

    async function fetchTextBottom() {
      let Text = await fetch(Texts?.[language()]?.WelcomeBottom)
        .then(response => response.text())
        .then(text => marked(text));

      setTextBottom(Text);
    }

    async function fetchTextPrivacy() {
      let Text = await fetch(Texts?.[language()]?.PrivacyPolicy)
        .then(response => response.text())
        .then(text => marked(text));

      setTextPrivacy(Text);
    }

    fetchTextTop();
    fetchTextBottom();
    fetchTextPrivacy();
  }, [_language]);

  return (
    <div className="screen screen-end">
      <div className="screen-end--inner">
        <div style={{ flex: 1 }}>
          <article dangerouslySetInnerHTML={{ __html: textTop }} />

          <p>
            <button
              onClick={showNext}
              className="inputs-button"
              style={{ margin: "25px 0" }}
            >
              {config.buttonStartLabel?.[language()]}
            </button>
          </p>

          <article dangerouslySetInnerHTML={{ __html: textBottom }} />

          <hr />

          <p>
            &copy; {year} -{" "}
            <a href={`mailto:${config.email}`}>{config.copyright}</a> |{" "}
            <a
              style={{ cursor: "pointer" }}
              onClick={event => {
                event.preventDefault();

                setShowPrivacyPolicy(!showPrivacyPolicy);
              }}
            >
              Privacy Policy
            </a>
          </p>

          <div
            style={{ display: showPrivacyPolicy ? "block" : "none" }}
            dangerouslySetInnerHTML={{ __html: textPrivacy }}
          />
        </div>
      </div>
    </div>
  );
}

String.prototype.replaceJSX = function (find, replace) {
  return this.split(find).flatMap(item => [item, replace]);
};
