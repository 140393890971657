import { useEffect, useState, lazy, Suspense } from "react";
import config from "../../config";
import { language } from "../../i18n/index.js";

const Back = lazy(() => import("../Icons/Back"));
const renderLoader = <></>;

export default function BackButton({ onClick }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 150);
  }, []);

  let className = "button-back";

  if (isVisible) {
    className = "button-back button-back--visible";
  }

  return (
    <div onClick={onClick} className={className}>
      <Suspense fallback={renderLoader}>
        <Back /> {config.buttonBackLabel?.[language()]}
      </Suspense>
    </div>
  );
}
