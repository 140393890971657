import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
} from "chart.js";
import { useEffect, useState, Fragment } from "react";
import { Radar } from "react-chartjs-2";
import Headline from "./Screen/Headline";
import config from "../config";
import {
  shareText,
  chartOptions,
  shortenUrl,
  printButton,
  parseUrl,
  pushToServer,
} from "../Utils";
import Share from "./Icons/Share";
import Printer from "./Icons/Printer";
import { language as lang, subscribeLanguageChange } from "../i18n/index.js";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip);

function dimensions1() {
  return Object.keys(config.dimensions).map(key => {
    const dimension = config.dimensions[key];

    return dimension.label?.[lang()];
  });
}

const data = {
  datasets: [
    {
      label: "",
      data: [],
      fill: true,
      backgroundColor: "rgba(3, 51, 78, 0.2)",
      borderColor: "rgb(3, 51, 78)",
      pointBackgroundColor: "rgb(3, 51, 78)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgb(3, 51, 78)",
    },
  ],
};

export default function ScreenEnd({ form }) {
  const [chartData, setChartData] = useState(data);
  const [shareUrl, setShareUrl] = useState(window.location.href);
  const [texts, setTexts] = useState([]);
  const [_form, _setForm] = useState(form);
  const [values, setValues] = useState({});
  const [language, setLanguage] = useState(lang());

  useEffect(() => {
    const { q, url } = parseUrl();

    // Erstelle URL
    if (!q) {
      // Sammel alle Werte für den Graphen

      config.questions.map((question, index) => {
        if (question.scope === "skala") {
          if (typeof values[question.dimension] === "undefined") {
            values[question.dimension] = 0;
          }

          values[question.dimension] += form[index] / 2;

          delete form[index];
        }
      });

      const base64 = btoa(
        btoa(
          JSON.stringify({
            ...form,
            dimensions: values,
          })
        )
      );

      setValues(values);

      url.searchParams.set("q", base64);
      window.history.pushState({}, "", url);

      shortenUrl(url, config, shortenedUrl => {
        setShareUrl(shortenedUrl);

        pushToServer(shortenedUrl, config, {
          ...form,
          dimensions: values,
        });
      });
    }

    // Abruf über URL
    else {
      form = JSON.parse(atob(atob(q || "")));

      setValues(form.dimensions);
      shortenUrl(shareUrl, config, shortenedUrl => {
        setShareUrl(shortenedUrl);
      });
    }

    _setForm(form);

    subscribeLanguageChange(setLanguage);
  }, []);

  useEffect(() => {
    // Sammel alle Texte für das Ergebnis
    setTexts(_texts(values));

    // Setze Chart Daten
    setChartData({
      ...chartData,
      datasets: [
        {
          ...chartData.datasets[0],
          data: Object.values(values),
        },
      ],
    });
  }, [values, language]);

  function _texts(values) {
    const _texts = [];

    Object.keys(values).map(key => {
      const dimension = config.dimensions[key];
      const value = values[key];

      if (value > 0) {
        _texts.push({
          text: dimension.conclusion[0]?.[language],
          value: values[key],
          headline: dimension.label?.[language],
          icon: dimension?.icon,
          iconViewBox: dimension?.iconViewBox || "0 0 512 512",
        });
      } else {
        _texts.push({
          text: dimension.conclusion[11]?.[language],
          value: values[key],
          headline: dimension.label?.[language],
          icon: dimension?.icon,
          iconViewBox: dimension?.iconViewBox || "0 0 512 512",
        });
      }
    });

    return _texts;
  }

  const getShareText = () => {
    const text = shareText
      .replace(/{{firstname}}/im, _form.firstname)
      .replace(/{{link}}/im, shareUrl || window.location.href);

    return text;
  };

  const shareButton = event => {
    event.preventDefault();

    navigator
      .share({
        title: "Negotiation analysis",
        text: getShareText(),
      })
      .then(() => {
        console.log("Thanks for sharing!");
      })
      .catch(console.error);
  };

  const renderText = texts => {
    return texts.map((text, index) => {
      return (
        <Fragment key={index}>
          <h3>
            <strong>
              {text.icon && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox={text.iconViewBox || "0 0 512 512"}
                  dangerouslySetInnerHTML={{ __html: text.icon }}
                />
              )}
              {text.headline}
            </strong>
          </h3>
          <p>{text.text}</p>
        </Fragment>
      );
    });
  };

  return (
    <div className="screen screen-end">
      <Headline>
        <strong>{config.headlineResults?.[language]}</strong>
      </Headline>

      <div className="screen-end--inner">
        <div className="screen-end--graph" style={{ maxWidth: "100%" }}>
          <Radar
            data={{ ...chartData, labels: dimensions1() }}
            width="350"
            height="200"
            options={chartOptions}
            style={{ maxWidth: "100%" }}
            key={`radar-${language}`}
          />
        </div>

        {renderText(texts)}

        <p>
          {navigator.share && navigator.canShare ? (
            <button
              onClick={shareButton}
              className="inputs-button"
              style={{ width: "100%" }}
            >
              <Share /> {config.buttonShareLabel?.[language]}
            </button>
          ) : (
            <a
              href={`mailto:?subject=${encodeURI(
                "Negotiation analysis"
              )}&body=${encodeURI(getShareText())}`}
              className="inputs-button"
              style={{ width: "100%" }}
            >
              <Share /> {config.buttonShareLabel?.[language]}
            </a>
          )}

          <a
            onClick={printButton}
            className="inputs-button"
            style={{ width: "100%" }}
          >
            <Printer /> {config.buttonPrintLabel?.[language]}
          </a>
        </p>
      </div>
    </div>
  );
}
