exports.defaultItems = [
  {
    title: "Fully agree",
    type: "button",
    weight: 1.0,
  },
  {
    title: "Agree",
    type: "button",
    weight: 0.5,
  },
  {
    title: "Not agree",
    type: "button",
    weight: 0.0,
  },
];

exports.chartOptions = {
  elements: {
    line: {
      borderWidth: 1,
    },
  },
  scales: {
    r: {
      angleLines: {
        display: true,
      },
      suggestedMin: 0,
      suggestedMax: 10,
      pointLabels: {
        font: {
          size: 14,
        },
      },
    },
  },
};

exports.shareText = `Dear

{{firstname}} has successfully completed the personal negotiation skills test.

Please find attached the link {{link}} to discuss the results.

Any questions? Feel free to reach out anytime.`;

exports.shortenUrl = (url, config, callback) => {
  if (!url || url.length === 0) {
    return false;
  }

  fetch("https://api-ssl.bitly.com/v4/shorten", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.bitlyToken,
    },
    body: JSON.stringify({
      domain: "bit.ly",
      long_url: url,
    }),
  })
    .then(res => res.json())
    .then(res => {
      callback(res.link);
    })
    .catch(e => {
      callback(url);
    });
};

exports.printButton = event => {
  event.preventDefault();

  window.print();
};

exports.parseUrl = () => {
  try {
    const url = new URL(window.location);
    const q = url.searchParams.get("q");

    return { q, url };
  } catch (e) {
    console.log("error", e);

    return { q: "", url: "" };
  }
};

exports.pushToServer = (url, config, inputs) => {
  const dimensions = [];

  Object.keys(inputs.dimensions).map(key => {
    dimensions.push({
      dimension: config.dimensions?.[key].label.de,
      value: inputs.dimensions[key],
    });
  });

  fetch(config.shareUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      form: {
        Vorname: inputs.firstname,
        Nachname: inputs.surname,
        "E-Mail Adresse": inputs.email,
      },
      summary: inputs,
      dimensions,
      url,
      apikey: "98c2b7657f373d0a987ced",
    }),
  }).then(res => {
    // console.log(res);
  });
};
