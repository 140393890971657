import { useState } from "react";
import { useLanguage } from "../i18n";
import Logo from "./Icons/Logo";

export default function Header({ languageChange }) {
  const [showPopup, setShowPopup] = useState(false);
  const [language, setLanguage] = useLanguage("de", languageChange);

  const _languageChange = lang => () => {
    setShowPopup(false);
    setLanguage(lang);
  };

  return (
    <header>
      <div className="container">
        <a href="/">
          <Logo />
        </a>

        <div className="language">
          <span onClick={() => setShowPopup(!showPopup)}>
            <img src={`/flags/${language}.png`} />
          </span>

          {showPopup && (
            <>
              <ul className="popup">
                <li onClick={_languageChange("de")}>deutsch</li>
                <li onClick={_languageChange("en")}>english</li>
              </ul>
              <div
                className="language-background"
                onClick={() => setShowPopup(false)}
              ></div>
            </>
          )}
        </div>
      </div>
    </header>
  );
}
