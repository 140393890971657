import Inputs from "./Screen/Inputs";
import Buttons from "./Screen/Buttons";
import Skala from "./Screen/Skala";
import Headline from "./Screen/Headline";
import BackButton from "./Screen/BackButton";

import { defaultItems } from "../Utils";
import { language } from "../i18n/index.js";

export default function Screen({
  id,
  question,
  visible,
  showNext,
  showPrev,
  current,
  onChange,
  form,
  config,
}) {
  return (
    <>
      {visible && (
        <div className="screen" id={id}>
          <BackButton
            visible={current !== 0}
            onClick={showPrev}
            config={config}
          />

          <Headline>{question.title?.[language()]}</Headline>

          {question.scope === "input" && (
            <Inputs
              items={question.items}
              onComplete={showNext}
              onChange={onChange}
              screenIndex={id}
              form={form}
              config={config}
            />
          )}
          {question.scope === "buttons" && (
            <Buttons
              items={question.items || defaultItems}
              onComplete={showNext}
              onChange={onChange}
              screenIndex={id}
              config={config}
            />
          )}
          {question.scope === "skala" && (
            <Skala
              item={question}
              onComplete={showNext}
              onChange={onChange}
              screenIndex={id}
              config={config}
              form={form}
            />
          )}
        </div>
      )}
    </>
  );
}
