import { useEffect, useState } from "react";

export default function Headline({ children }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  let className = "headline";

  if (isVisible) {
    className = "headline headline--visible";
  }

  return <h2 className={className}>{children}</h2>;
}
