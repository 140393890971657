import Progress from "./Progress";

export default function Footer({ current, total }) {
  return (
    <footer>
      <div className="container">
        <Progress current={current} total={total} />
      </div>
    </footer>
  );
}
