import { useEffect, useState, lazy, Suspense } from "react";
import { language } from "../../i18n/index.js";
import config from "../../config";

import "../../css/skala.css";

const ButtonNext = lazy(() => import("./ButtonNext"));
const renderLoader = <></>;

export default function Skala({
  item,
  screenIndex,
  onChange,
  onComplete,
  onBackButton,
  form,
}) {
  const _screenIndex = screenIndex.match(/screen-(\d+)/)[1];
  const [value, setValue] = useState(form?.[_screenIndex] || item?.value || 5);

  const onButtonNext = () => {
    onChange(_screenIndex, value);
    onComplete();
  };

  const index = 0;

  return (
    <>
      <div className="skala">
        <Item
          item={item}
          onClick={value => {
            setValue(value);
          }}
          index={index}
          screenIndex={screenIndex}
          value={value}
          key={`${screenIndex}-${index}`}
        />
      </div>

      <Suspense fallback={renderLoader}>
        <ButtonNext onNext={onButtonNext} onBack={onBackButton} />
      </Suspense>
    </>
  );
}

const Item = ({ item, onClick, index, screenIndex, value }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, (index + 1) * 100);
  }, []);

  let className = ["skala-item"];

  if (visible) {
    className.push("skala-item--visible");
  }

  const renderItems = () => {
    let items = [];

    for (let i = item.minValue || 1; i <= (item.maxValue || 10); i++) {
      let classes = ["skala--container-item"];

      if (i === value) {
        classes.push("skala--container-item--active");
      }

      items.push(
        <div
          className={classes.join(" ")}
          key={`${screenIndex}-${index}-${i}`}
          onClick={() => onClick(i)}
        />
      );
    }

    return items;
  };

  return (
    <>
      <div
        className={className.join(" ")}
        key={`button-${screenIndex}-${index}`}
      >
        {/* {item.title && <label>{item.title?.[language()]}</label>} */}
        <div className="skala--container">{renderItems()}</div>
        <div className="skala--legend">
          <span>{(item.minLabel || config.defaultMinLabel)?.[language()]}</span>
          <span>{(item.maxLabel || config.defaultMaxLabel)?.[language()]}</span>
        </div>
      </div>
    </>
  );
};
