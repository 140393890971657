import { useEffect, useState } from "react";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./components/Main";
import config from "./config";
import { language as lang } from "./i18n";

export default function App() {
  const [current, setCurrent] = useState(-1);
  const [language, setLanguage] = useState(lang());
  const [form, setForm] = useState({
    length: config.questions.length,
  });

  useEffect(() => {
    const url = new URL(window.location);
    const q = url.searchParams.get("q");

    if (q) {
      setCurrent(config.questions.length);
    }
  }, []);

  const onLanguageChange = _lang => setLanguage(_lang);

  return (
    <>
      <Header languageChange={onLanguageChange} />
      <Main
        current={current}
        setCurrent={setCurrent}
        config={config}
        form={form}
        setForm={setForm}
        language={language}
      />

      {current >= 0 && current < config.questions.length && (
        <Footer current={current} total={config.questions.length} />
      )}
    </>
  );
}
