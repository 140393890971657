import { Fragment } from "react";
import "../css/progress.css";

export default function Progress({ current, total }) {
  const progress = Math.round(Math.max(current / total, 0) * 100);

  return (
    <Fragment>
      {current >= 0 && (
        <div className="progress">
          <div className="progress--status">{progress}% Complete</div>
          <div className="progress--bar">
            <div
              className="progress--bar-inner"
              style={{ width: progress + "%" }}
            ></div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
