import { Fragment } from "react";
import Screen from "./Screen";
import ScreenEnd from "./ScreenEnd";
import Welcome from "./Welcome";
import config from "../config";

const allQuestions = config.questions;
const questions = [
  ...shuffle(allQuestions.slice(0, -2)),
  ...allQuestions.slice(-2),
];

export default function Main({ current, setCurrent, form, setForm, language }) {
  const onChange = (id, value) => {
    form[id] = value;

    setForm({
      ...form,
      [id]: value,
    });
  };

  const showNext = index => () => setCurrent(index + 1);
  const showPrev = index => () => setCurrent(index - 1);

  return (
    <main>
      <div className="container">
        {questions.map((question, index) => {
          if (index === current) {
            return (
              <Screen
                id={`screen-${index}`}
                key={`screen-${index}`}
                question={question}
                visible={index === current}
                current={current}
                showNext={showNext(index)}
                showPrev={showPrev(index)}
                onChange={onChange}
                form={form}
                config={config}
              />
            );
          }

          return <Fragment key={`screen-blank-${index}`}></Fragment>;
        })}

        {current < 0 && (
          <Welcome
            showNext={showNext(-1)}
            config={config}
            language={language}
          />
        )}

        {current >= questions.length && (
          <ScreenEnd form={form} questions={questions} />
        )}
      </div>
    </main>
  );
}

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}
