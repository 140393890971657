import { useEffect, useState } from "react";
import LocalizedStrings from "react-localization";

const i18n = new LocalizedStrings({
  de: {},
  en: {},
});

const subscriber = [];

function subscribe(callback) {
  if (typeof callback === "function") subscriber.push(callback);
}

function getLanguage() {
  const allowedLanguages = ["de", "en"];
  const language = window.localStorage.getItem("language") || i18n.getLanguage();

  if (allowedLanguages.includes(language)) {
    return language;
  }

  return "en";
}

export function useLanguage(init, onChange) {
  const [language, setLanguage] = useState(init);

  useEffect(() => {
    setLanguage(getLanguage());
  }, []);

  useEffect(() => {
    window.localStorage.setItem("language", language);

    if (typeof onChange === "function") onChange(language);

    subscriber.forEach(callback => callback(language));
  }, [language]);

  return [language, setLanguage];
}

export const language = getLanguage;
export const subscribeLanguageChange = subscribe;

export default i18n;
